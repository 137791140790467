import React, { useContext, useEffect, useState } from "react";
import { Props } from "./interface";
import styles from "./Section.module.css";
import {
  arrayIsEmpty,
  getImgPath,
  getSizes,
} from "../../../../helpers/functions";
import DynamicObject from "../../../../models/dynamic-object";
import SectionCacher from "../../../../models/section-cacher";
import { ROW_DIRECTION } from "../../../../constants/grid";
import GridLayout from "../../../../grid-layout";
import UIContext from "../../../../storage/UIContext";
import { getDataStorage } from "../../../../helpers/storage.helper";
import FlowReferences from "../../../../flow-references";
import PostContext from "../../../../storage/PostContext";
import { useValidation } from "./context/ValidationContext";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import { isValidRateGroup } from "./rateEnums";
const Section = (props: Props): JSX.Element => {
  const { config } = props;
  const storage = getDataStorage();
  const uiCtx = useContext(UIContext);
  const postCtx = useContext(PostContext);
  const { error:validationError, hideError} = useValidation(); // Access validation error message from context
  const [childAction, setChildAction] = useState("");

  const isMobileOrDesktopStyle = {
    border:
      props.config?.border &&
      `1px ${
        props.config?.border?.type.toLowerCase() == "none" &&
        props.config?.border?.color
          ? "solid"
          : props.config?.border?.type
      } ${props.config?.border?.color}`,
    borderRadius: props.config?.border?.round ? "15px" : "5px",
  };

  /************************************
   *           CSS STYLE
   * *********************************/
  const containerStyle = {
    // "--background-color": props.config?.background?.color,
    // "--border-color": props.config?.border?.color,
    backgroundColor: props.config?.background?.color ?? "",
    gridArea: props.config.identifier,
    // gridGap:"50px",
    borderRadius: isMobileOrDesktopStyle.borderRadius,
    border: isMobileOrDesktopStyle.border,

    boxShadow:
      props.config.background &&
      props.config.border &&
      "0px 2px 16px rgba(190, 196, 209, 0.4)",
    // gap:"50px",
    padding:
      storage.isMobile && postCtx.data.step.type !== "previewStep"
        ? "8px 16px"
        : !props.config.background && !props.config.border
        ? "0"
        : props.config.background&&!props.config.title?"0":"22px 16px",
  } as React.CSSProperties;

  /************************************
   *      INLINE TITLE STYLE
   * *********************************/
  const titleStyle = {
    fontWeight: config.title?.style?.bold ? "bold" : "normal",
    fontSize: getSizes(config.title?.style?.size),
    color: config.title?.color,
    margin: "5px 0px 5px 0px",
  } as React.CSSProperties;

  /************************************
   *     INLINE SUBTITLE STYLE
   * *********************************/
  const subTitleStyle = {
    fontWeight: config.subTitle?.style?.bold ? "bold" : "normal",
    fontSize: getSizes(config.subTitle?.style?.size),
    color: config.subTitle?.color,
  } as React.CSSProperties;

  const checkTypeOfSectionChild = (type: string) => {
    const targetChild = props.config.childs.filter(
      (child) => child.type.toLowerCase() == type.toLowerCase()
    );
    if (targetChild?.length == 1) {
      return targetChild[0].identifier;
    } else {
      return false;
    }
  };
  FlowReferences.load(config.identifier, {
    config: {
      set: props.setConfig,
      get: () => config,
    },
  });

  useEffect(() => {
    const result = checkTypeOfSectionChild("textLabelWithAction");
    if (result) setChildAction(result);
  }, []);

  const attributes: DynamicObject = {
    id: props.config.identifier,
    className: styles.container,
    style: containerStyle,
  };
  if (childAction) {
    const callback = SectionCacher.getInstance().get(childAction);
    if (callback) {
      attributes.className = `${attributes.className} cursor__pointer`;
      attributes.onClick = () => {
        callback();
      }; 
    }
  }
  const childContainerStyle = {} as React.CSSProperties;
  if (props.config?.layout?.direction === ROW_DIRECTION) {
    const gridLayout = GridLayout.createInstance();
    props.config.childs.map(function (component) {
      const max = Math.max(...config.layout.columns);
      gridLayout.load(component.identifier, component?.layout?.columns, max);
    });
    childContainerStyle.display = "grid";
    childContainerStyle.gridGap = "20px";
    childContainerStyle.gridTemplateAreas = `'${gridLayout.draw()}'`;
    // childContainerStyle['grid-template-columns'] =`repeat(${gridLayout.gridCols.length}, 1fr)`
    childContainerStyle.gridTemplateColumns = gridLayout.getGridColumns();
    // childContainerStyle['grid-template-columns'] = '2fr 1fr'
  } else if (!props.config?.layout?.childsColumns) {
    childContainerStyle.display = "flex";
    childContainerStyle.flexDirection = "column";
    childContainerStyle.gridGap = storage.isMobile
      ? "12px"
      : props.config.identifier.toLowerCase().includes("mega")
      ? "24px"
      : "10px";
  }
  
  if (props.config?.layout?.childsColumns) {
    let templatesAreas = [];
    props.config.childs.map((child) => {
      templatesAreas.push(child.identifier);
    });
      childContainerStyle.display = "grid";
      if(window.innerWidth<1000){
        childContainerStyle.display = "block";
      }

    childContainerStyle.gridGap = storage.isMobile
    ? "5px"
    : props.config.identifier.toLowerCase().includes("mega")
    ? "24px"
    : "24px";

    childContainerStyle.gridTemplateAreas = `'${templatesAreas.join(" ")}'`;
    childContainerStyle.gridTemplateColumns = Array(
      props.config.layout.childsColumns
    )
      .fill("1fr")
      .join(" ");
  }
  return (
    (!arrayIsEmpty(config.childs) || !props.config?.hide) && (
<div className={styles.container} style={containerStyle}>
  {/* Title Section */}
  {config?.title && (config.title.locale || config.title.icon) && (
    <div>
        <div className={styles.flexContainer}>
          {config.title.icon && (
            <div>
              <img style={{ width: "26px" }} src={getImgPath(config.title.icon)} />
            </div>
          )}
          {config.title.locale && (
          <div className={`mb-3`}>
              <span style={titleStyle}>{config.title.locale}</span>
            </div>
          )}
        </div>
      </div>
  )}

  {/* Subtitle Section */}
  {config?.subTitle && (config.subTitle.locale || config.subTitle.icon) && (
    <div style={{ display: "flex", gap: "5px" }}>
      <div className={styles.flexContainer}>
        {config.subTitle.icon && (
          <div>
            <img style={{ width: "20px" }} src={getImgPath(config.subTitle.icon)} />
          </div>
        )}
        {config.subTitle.locale && (
          <div className={`mb-3`}>
            <span style={subTitleStyle}>{config.subTitle.locale}</span>
          </div>
        )}
      </div>
    </div>
  )}

  {/* Children and Validation Error */}
  <div style={childContainerStyle}>{props.children}</div>
  {!hideError && isValidRateGroup(config.identifier) && (
    <ValidationError validation={{ error: validationError.current }} />
  )}
</div>

    )
  );
};

export default Section;