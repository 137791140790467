import React, {useContext} from "react";
import DynamicObject from "../../../models/dynamic-object";
import {UseHttpResponse} from "../../../hooks/use-http/interfaces";
import {Button as MuiButton} from "@mui/material";
import {getSizes} from "../../../helpers/functions";
import UIContext from "../../../storage/UIContext";
import styles from "./NextButton.module.css"
import {getDataStorage} from "../../../helpers/storage.helper";
import useSubmitContext from "../../../storage/SubmitContext";
import PostContext from "../../../storage/PostContext";


interface NextButtonProps {
    nextApi?: UseHttpResponse<DynamicObject>;
    style?: {
        bold?: boolean,
        size?: string,
        color?: string,
        backgroundColor?: string
        borderRadius?: string
        marginTop?: string
        width?: string
    },
    children?: React.ReactNode;
    className?: string
    onClick?: Function
    isSticky: boolean
}


const NextButton = (props: NextButtonProps): JSX.Element => {
    let {nextApi, style, children, isSticky} = props
    const {isSubmitUpdated, submitObj} = useSubmitContext();
    const postCtx = useContext(PostContext)

    if(isSubmitUpdated) {
        style = {
            ...props.style,
            backgroundColor: submitObj?.background?.color ?? style?.backgroundColor,
            borderRadius: submitObj?.borderRadius ?? style?.borderRadius,
            color: submitObj?.color ?? style?.color,
            bold: submitObj?.style?.bold ?? style?.bold,
            size: submitObj?.style?.size ?? style?.size
        };
        children = submitObj?.locale ?? children
        isSticky = submitObj?.sticky ?? isSticky
    }
    const uiCtx = useContext(UIContext)
    const storage = getDataStorage()
    const submitStyle = {
        fontWeight: style?.bold ? "bold" : "normal",
        fontSize: getSizes(style?.size || "m"),
        width: style.width,
        borderRadius: style?.borderRadius ? style.borderRadius : "8px",
        color: style?.color,
        backgroundColor: style?.backgroundColor,
        margin: isSticky ? "5px" : "0px",
        padding: "10px",
        textTransform:"none",
        paddingBottom:"15px",
        paddingTop:"15px"
    } as React.CSSProperties

    const nextHandler = () => {
        if (props.onClick) {
           if((((typeof submitObj?.active) === "boolean") &&  !submitObj?.active) || (!isSubmitUpdated && ((typeof postCtx.data?.step?.submit?.active) === "boolean") && !postCtx.data?.step?.submit?.active)) return
           return props.onClick(nextApi)
        }
        nextApi?.request({})
    }

    return (
        <div
            className={`${isSticky ? storage.isMobile ? styles.button_container : styles.button_container_in_desktop : ''}`}
            style={{width: "100%",padding:storage.isMobile?'0px':'5px 0px'}}>
            <div className={`${storage.isMobile ? `d-flex justify-content-center align-items-center w-100 ${styles.background_white}`:styles.button_subcontainer_in_desktop}`}>
                <MuiButton disabled={uiCtx.btnLoading && true} id={'next-btn'} variant='contained' style={submitStyle}
                           className={`d-block`}
                           onClick={(e: React.MouseEvent<HTMLElement>) => nextHandler()}>{children}

                </MuiButton>
            </div>
        </div>

    )
}

export default NextButton;
